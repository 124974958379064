<template>
  <div class="dark-bg">
    <bread-crumb></bread-crumb>
    <div class="network__descr" v-if="description" v-html="description"></div>
    <div class="network__wrap fs32">
      <div class="cart__wrap container container__mini">
        <Cart
          v-for="children in childrens"
          :data="children"
          :key="children.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
const Cart = defineAsyncComponent(() => import("@/components/Cart.vue"));
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    BreadCrumb,
    Cart,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      description: "",
      childrens: [],
      data: [],
    };
  },
  methods: {
    async getService() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/services/search/" +
            clear_url,
          config
        );
        this.description = response.data.data[0].description;
        this.childrens = response.data.data[0].children;
        this.data = response.data.data[0];
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getService();
  },
  updated() {
    document.title = this.data.name + " - iIT Trading";
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.network {
  &__descr {
    max-width: 841px;
    padding: 0 15px;
    margin: 0 auto;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 50, 25);
    line-height: calc(25 / 16);
    color: #fff;

    strong {
      display: block;
    }
  }

  &__wrap {
    @include property("padding-top", 150, 50);
    @include property("padding-bottom", 150, 50);

    @media (max-width: 500px) {
      padding-right: 20px;
    }
  }
}

.cart {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 767.98px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
